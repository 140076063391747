<template>
  <div :id="`card-element-${cardId}`">
    <section slot="pdf-content">
      <div class="card down">
        <div class="card-header">
          <div class="row">
            <div class = "col-sm-10" @click="toggleCollapse()">
              <font-awesome-icon icon="chevron-circle-down" v-if="collapse" />
              <font-awesome-icon icon="chevron-circle-up" v-else />
              &nbsp;
              <slot name="card-header" />
            </div>
            <div class = "col-sm-1 right">
              <font-awesome-icon icon="info" v-b-popover.hover.focus="hovertext"/><br>
            </div>
            <!-- <div class = "col-sm-1 right" @mouseover="hover = true" @mouseleave="hover = false">
              <font-awesome-icon v-if="hover">this graph is about</font-awesome-icon>
              <font-awesome-icon icon="info" v-else />
            </div> -->
            <div class = "col-sm-1">
              <font-awesome-icon icon="download" @click="printPDF()"/><br>
            </div>
          </div>
        </div>

        <div class="card-body" v-if="!collapse">
          <slot name="card-body" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>

import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'

// set a canvas attributes before getting a context as they can only be set once at creation time
HTMLCanvasElement.prototype.getContext = function(origFn) {
  return function(type, attribs) {
    attribs = attribs || {};
    attribs.preserveDrawingBuffer = true;
    return origFn.call(this, type, attribs);
  };
}(HTMLCanvasElement.prototype.getContext);

export default {
  name: 'Card',
  // components: {
  //       VueHtml2pdf
  //     },
  props: {
    hovertext: String,
    cardId: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      collapse: false,
      hover: false,
      height: 500,
    }
  },
  methods: {
    toggleCollapse: function () {
      this.collapse = !this.collapse
    },
    printPDF () {
      // get component size
      const cardElement = document.getElementById(`card-element-${this.cardId}`)
      // dividing the pixel values by the pixel density as 1 point is equal to 1/72 of an inch.
      const width = cardElement.offsetWidth / 96 * 72
      const height = cardElement.offsetHeight / 96 * 72

      html2canvas(cardElement)
        .then(canvas => {
          const pdf = new jsPDF({orientation: 'portrait'}, 'pt',[width,height])
          pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 0,0)
          pdf.save(`${this.cardId}-card.pdf`)
        })
    },
  },
}

</script>

<style scoped>

.card-header:hover {
  cursor: pointer;
}

.card-header {
  height: 30px;
  padding: 5px 5px 5px 20px;
  color: white;
  background-color: var(--color-1) !important;
  border-color: var(--color-1) !important;
  text-align: left;
}

.card-body {
  background-color: white !important;
  border: 1px var(--color-4) solid;
  text-align: left;
  font-size: 12px;
  font-style: italic;
  /* height: 500px; */
/*  max-height: 80vh;
  overflow-y: auto;*/
}

.right {
  margin-left: 10px;
}

.col-sm-1 {
  margin-right: -20px;
}

</style>
