<template>
  <vue-plotly
    style="width: 100%;"
    :data="plotData"
    :layout="layout"
    :options="options"
  />
</template>

<script>
import VuePlotly from '@statnett/vue-plotly';

export default {
  name: 'PlotlyTable',
  components: { VuePlotly },
  props: {
    x: {
      type: Array,
      default: () => []
    },
    y: {
      type: Array,
      default: () => []
    },
    z: {
      type: Array,
      default: () => []
    },
    w: {
      type: Array,
      default: () => []
    },
    v: {
      type: Array,
      default: () => []
    },
    t: {
      type: Array,
      default: () => []
    },
    xtitle: {
      type: String,
      default: ''
    },
    ytitle: {
      type: String,
      default: ''
    },
    ztitle: {
      type: String,
      default: ''
    },
    wtitle: {
      type: String,
      default: ''
    },
    vtitle: {
      type: String,
      default: ''
    },
    ttitle: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    showlegend: {
      type: Boolean,
      default: false
    },
    tableHeight: {
      type: Number,
      default: 2000
    },
  },
  data() {
    return {
      layout: {
        paper_bgcolor: 'rgba(0,0,0,0)',
        plot_bgcolor: 'rgba(0,0,0,0)',
        height: this.tableHeight,
        showlegend: this.showlegend,
      },
      options: {
        responsive: true,
        maintainAspectRatio: false
      },
    };
  },
  computed: {
    // plotData() {
    //   const tableData = [
    //     {
    //       type: 'table',
    //       header: {
    //         values: [this.xtitle, this.ytitle, this.ztitle],
    //         align: 'center',
    //         fill: { color: '#144273' },
    //         font: { color: 'white', size: 14 },
    //       },
    //       cells: {
    //         values: [this.x, this.y, this.z],
    //         align: 'center',
    //         // font: { color: '#333', size: 12 },
    //       },
    //     },
    //   ];

    //   return tableData;
    // },
    plotData() {
      const tableData = [
        {
          type: 'table',
          header: {
            values: this.getTableHeader(),
            align: 'center',
            fill: { color: '#144273' },
            font: { color: 'white', size: 14 },
          },
          cells: {
            values: this.getTableValues(),
            align: 'center',
          },
        },
      ];

      return tableData;
    },
  },
  methods: {
    getTableHeader() {
      const header = [];
      if (this.xtitle) header.push(this.xtitle);
      if (this.ytitle) header.push(this.ytitle);
      if (this.ztitle) header.push(this.ztitle);
      if (this.wtitle) header.push(this.wtitle);
      if (this.vtitle) header.push(this.vtitle);
      if (this.ttitle) header.push(this.ttitle);
      return header;
    },
    getTableValues() {
      const values = [];
      if (this.x.length > 0) values.push(this.x);
      if (this.y.length > 0) values.push(this.y);
      if (this.z.length > 0) values.push(this.z);
      if (this.w.length > 0) values.push(this.w);
      if (this.v.length > 0) values.push(this.v);
      if (this.t.length > 0) values.push(this.t);
      return values;
    },
  },
};
</script>

<style scoped>


</style>