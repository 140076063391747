<template>
  <div :id="`card-element-${cardId}`">
    <section slot="pdf-content">
      <div class="card down">
        <div class="card-header">
          <div class="row">
            <div class="col-sm-9" @click="toggleCollapse()" @keydown.enter.prevent="toggleCollapseOnEnter">
              <font-awesome-icon tabindex="0" icon="chevron-circle-down" v-if="collapse" />
              <font-awesome-icon tabindex="0" icon="chevron-circle-up" v-else />
              &nbsp;
              <slot name="card-header" />
            </div>
            <div class="col-sm-3 text-right">
              <div class="d-flex justify-content-end align-items-center">
                <div style="margin-right: 20px;" @click="toggleTableComponent()"
                  @keydown.enter.prevent="toggleTableComponentOnEnter">
                  <font-awesome-icon tabindex="0" class="tableIcon" icon="table" />
                </div>
                <div style="margin-right: 20px;" @click="showInfo()" @keydown.enter.prevent="showInfoOnEnter">
                  <font-awesome-icon class="infoIcon" icon="info" v-b-popover.hover.focus="hovertext" />
                </div>
                <div style="margin-right: 20px;" @click="printPDF()" @keydown.enter.prevent="handlePrintKeyDown"
                  ref="printIcon" tabindex="0">
                  <font-awesome-icon icon="download" class="printIcon" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card-body" v-if="!collapse" :style="fontStyle">
          <slot name="card-body" />
          <div class="table-section" v-if="showTable">
            <Table tabindex="0" :x="x" :y="y" :z="z" :w="w" :v="v" :xtitle="xtitle" :ytitle="ytitle" :ztitle="ztitle"
              :wtitle="wtitle" :vtitle="vtitle" :tableHeight="tableHeight" />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import Table from '../components/Table'


// set a canvas attributes before getting a context as they can only be set once at creation time
HTMLCanvasElement.prototype.getContext = function (origFn) {
  return function (type, attribs) {
    attribs = attribs || {};
    attribs.preserveDrawingBuffer = true;
    return origFn.call(this, type, attribs);
  };
}(HTMLCanvasElement.prototype.getContext);

export default {
  name: 'Card',
  components: { Table },
  props: {
    cardId: {
      type: String,
      required: true
    },
    hovertext: String,
    fontsize: {
      type: Number,
      default: 12
    },
    x: {
      type: Array,
      default: () => []
    },
    y: {
      type: Array,
      default: () => []
    },
    z: {
      type: Array,
      default: () => []
    },
    w: {
      type: Array,
      default: () => []
    },
    v: {
      type: Array,
      default: () => []
    },
    t: {
      type: Array,
      default: () => []
    },
    xtitle: String,
    ytitle: String,
    ztitle: String,
    wtitle: String,
    vtitle: String,
    ttitle: String,
    tableHeight: {
      type: Number,
      default: 2000,
    },
  },
  data() {
    return {
      collapse: false,
      hover: false,
      height: 500,
      showTable: false,
    }
  },
  computed: {
    fontStyle() {
      return `font-size: ${this.fontsize}px;`
    },
  },
  methods: {
    toggleCollapse: function () {
      this.collapse = !this.collapse
    },
    toggleCollapseOnEnter(event) {
      if (event.key === 'Enter') {
        this.toggleCollapse();
      }
    },
    toggleTableComponent() {
      this.showTable = !this.showTable; // toggle the visibility of the Table component
    },
    toggleTableComponentOnEnter(event) {
      if (event.key === 'Enter') {
        this.toggleTableComponent();
      }
    },
    printPDF() {
      // get component size
      const cardElement = document.getElementById(`card-element-${this.cardId}`)
      // dividing the pixel values by the pixel density as 1 point is equal to 1/72 of an inch.
      const width = cardElement.offsetWidth / 96 * 72
      const height = cardElement.offsetHeight / 96 * 72

      html2canvas(cardElement)
        .then(canvas => {
          const pdf = new jsPDF({ orientation: 'landscape' }, 'pt', [width, height])
          pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0)
          pdf.save(`${this.cardId}-card.pdf`)
        })
    },
    handlePrintKeyDown(event) {
      if (event.key === 'Enter') {
        // calling the printPDF method when Enter key is pressed
        this.printPDF();
      }
    },
  },
}

</script>

<style scoped>
.card-header:hover {
  cursor: pointer;
}

.card-header {
  height: 60px;
  padding: 5px 5px 5px 20px;
  color: white;
  background-color: var(--color-1) !important;
  border-color: var(--color-1) !important;
  text-align: left;
}

.card-body {
  display: flex;
  flex-direction: column;
  /* vertically stacks components */
  background-color: white !important;
  border: 1px var(--color-4) solid;
  text-align: left;
  /* font-size: 12px; */
  font-style: italic;

  /*  max-height: 80vh;
  overflow-y: auto;*/
}

.right {
  margin-left: 10px;
}

.col-sm-1 {
  margin-right: -20px;
}

.table-section {
  margin-top: 5px;
}

.tableIcon,
.infoIcon,
.printIcon {
  color: var(--color-2);
  outline: none;
}

.tableIcon:focus,
.infoIcon:focus,
.printIcon:focus {
  color: var(--color-3);
  outline: none;
}

.tableIcon:hover,
.infoIcon:hover,
.printIcon:hover {
  color: var(--color-3);
  outline: none;
}
</style>
