<template>
  <div class="dashboard p-5" id="dementie-information">
    <div class="row">
      <div class="col-sm-12 down">
        <h1 class="intro-section-title">Leven met Dementie - Wlz</h1>
        <div class="row">
          <p class="intro-section-text">
            <b>Toelichting:</b> Onderstaand dashboard geeft informatie over de Wlz (wet langdurige zorg) als onderdeel
            van het levenspad leven met dementie. Het geeft inzicht in het gebruik van langdurige zorg gefinancierd
            vanuit de Wlz door mensen met dementie. Specifiek gaat dit overzicht over de verschillende leveringsvormen
            van Wlz. De groep met leveringsvorm zorg met verblijf bevat mensen met dementie die verblijven in een
            zorginstelling en daar Wlz-zorg ontvangen. De groep met leveringsvorm volledig pakket thuis bevat mensen met
            dementie die thuis verblijven en alle geïndiceerde Wlz-zorg thuis ontvangen. De groep modulair pakket thuis
            bevat mensen met dementie die een deel van de onderdelen van het zorgpakket ontvangen (bijv. dagbesteding).
            PGB is PGB en deze groep bevat mensen met dementie die een budget ontvangen om zelf contracten af te sluiten
            voor zorg met zorgverleners. Het overzicht wordt gegeven met zowel landelijke als regionale cijfers op het
            niveau van zorgkantoorregio’s. Dit overzicht gaat over gegevens uit de jaren 2015-2020. Deze informatie is
            vanwege het risico op onthulling van individuele zorginstellingen (voorlopig) alleen op
            zorgkantoorregio-niveau beschikbaar.
          </p>
          <p class="intro-section-text">
            <b>Populatie:</b> De populatie bestaat uit alle mensen met dementie die Wlz-zorg ontvangen. De onderstaande
            grafieken geven percentages weer van verschillende leveringsvormen ten opzichte van het totaal aantal mensen
            met dementie dat Wlz ontvangt.
          </p>
        </div>
      </div>
    </div>

    <div class="row" v-if="selectedLevel === 'zorgkantoor'">
      <div class="col-sm-12 down">
        <card :cardId="`Leveringsvorm Wlz Verblijf in ${selectedYear}`"
          :hovertext="'Bij het bepalen of iemand is opgenomen in een Wlz-instelling is gebruik gemaakt van de gegevens van het CAK (GEBWLZTAB), waarbij de opnamedatum is gedefinieerd als de datum waarop de levering van zorg in natura in een instelling is gestart (en gebruik van andere leveringsvormen dus wordt genegeerd). Deze informatie is vanwege het risico op onthulling van individuele zorginstellingen (voorlopig) alleen op zorgkantoorregio-niveau beschikbaar. De data over de mensen met dementie komt uit de cbs microdata.'"
          :x="zorgkantoorregionaam_natura" :y="percentage_natura_table.map(value => `${value}%`)"
          :xtitle="'Regio namen'" :ytitle="'Percentage'"
          :tableHeight="selectedLevel === 'dementienetwerk' ? 1600 : 900">
          <template slot="card-header">Leveringsvorm Wlz Verblijf in {{ selectedYear }}</template>
          <div slot="card-body">
            In dit overzicht is het percentage mensen met dementie te zien dat Wlz-zorg met verblijf ontvangt ten
            opzichte van het
            totaal aantal mensen dat Wlz ontvangt. Deze informatie is uitsluitend op zorgkantoorregio-niveau
            beschikbaar.
            <stacked-bar-chart-colors v-if="load.graphOne" :names="['Natura']" :ytitle="'% Wlz met verblijf'"
              :x="[zorgkantoorregionaam_natura]" :y="[percentage_natura]" :ticksuffix="'%'" :showlegend="false" />
          </div>
        </card>
      </div>
    </div>

    <div class="row" v-if="selectedLevel === 'dementienetwerk'">
      <div class="col-sm-12 down">
        <card-small :cardId="'Leveringsvorm Wlz Verblijf'"
          :hovertext="'Bij het bepalen of iemand is opgenomen in een Wlz-instelling is gebruik gemaakt van de gegevens van het CAK (GEBWLZTAB), waarbij de opnamedatum is gedefinieerd als de datum waarop de levering van zorg in natura in een instelling is gestart (en gebruik van andere leveringsvormen dus wordt genegeerd). Deze informatie is vanwege het risico op onthulling van individuele zorginstellingen (voorlopig) alleen op zorgkantoorregio-niveau beschikbaar. De data over de mensen met dementie komt uit de cbs microdata.'">
          <template slot="card-header">Leveringsvorm Wlz Verblijf - uitsluitend op zorgkantoorregio-niveau</template>
        </card-small>
      </div>
    </div>

    <div class="row" v-if="selectedLevel === 'zorgkantoor'">
      <div class="col-sm-12 down">
        <card :cardId="`Leveringsvorm Wlz volledig pakket thuis in ${selectedYear}`"
          :hovertext="'Deze informatie is vanwege het risico op onthulling van individuele zorginstellingen (voorlopig) alleen op zorgkantoorregio-niveau beschikbaar. De data over de mensen met dementie komt uit de cbs microdata.'"
          :x="zorgkantoorregionaam_vpt" :y="percentage_volledig_pakket_thuis_table.map(value => `${value}%`)"
          :xtitle="'Regio namen'" :ytitle="'Percentage'"
          :tableHeight="selectedLevel === 'dementienetwerk' ? 1600 : 900">
          <template slot="card-header">Leveringsvorm Wlz volledig pakket thuis in {{ selectedYear }}</template>
          <div slot="card-body">
            In dit overzicht is het percentage mensen met dementie te zien dat Wlz ontvangt in de vorm van volledig
            pakket
            thuis ten opzichte van het totaal aantal mensen dat Wlz ontvangt. Deze informatie is uitsluitend op
            zorgkantoorregio-niveau beschikbaar.
            <stacked-bar-chart-colors v-if="load.graphOne" :names="['Volledig pakket thuis']"
              :ytitle="'% met volledig pakket thuis'" :x="[zorgkantoorregionaam_vpt]"
              :y="[percentage_volledig_pakket_thuis]" :ticksuffix="'%'" :showlegend="false" />
          </div>
        </card>
      </div>
    </div>

    <div class="row" v-if="selectedLevel === 'dementienetwerk'">
      <div class="col-sm-12 down">
        <card-small :cardId="'Leveringsvorm Wlz volledig pakket thuis'" v-if="selectedLevel === 'dementienetwerk'"
          :hovertext="'Deze informatie is vanwege het risico op onthulling van individuele zorginstellingen (voorlopig) alleen op zorgkantoorregio-niveau beschikbaar. De data over de mensen met dementie komt uit de cbs microdata.'">
          <template slot="card-header">Leveringsvorm Wlz volledig pakket thuis - uitsluitend op
            zorgkantoorregio-niveau</template>
        </card-small>
      </div>
    </div>

    <div class="row" v-if="selectedLevel === 'zorgkantoor'">
      <div class="col-sm-12 down">
        <card :cardId="`Leveringsvorm Wlz modulair pakket thuis in ${selectedYear}`"
          :hovertext="'Deze informatie is vanwege het risico op onthulling van individuele zorginstellingen (voorlopig) alleen op zorgkantoorregio-niveau beschikbaar. De data over de mensen met dementie komt uit de cbs microdata.'"
          :x="zorgkantoorregionaam_mpt" :y="percentage_modulair_pakket_thuis_table.map(value => `${value}%`)"
          :xtitle="'Regio namen'" :ytitle="'Percentage'"
          :tableHeight="selectedLevel === 'dementienetwerk' ? 1600 : 900">
          <template slot="card-header">Leveringsvorm Wlz modulair pakket thuis in {{ selectedYear }}</template>
          <div slot="card-body">
            In dit overzicht is het percentage mensen met dementie te zien dat Wlz ontvangt in de vorm van modulair
            pakket
            thuis ten opzichte van het totaal aantal mensen dat Wlz ontvangt. Deze informatie is uitsluitend op
            zorgkantoorregio-niveau beschikbaar.
            <stacked-bar-chart-colors v-if="load.graphOne" :names="['Modulair pakket thuis']"
              :ytitle="'% met modulair pakket thuis'" :x="[zorgkantoorregionaam_mpt]"
              :y="[percentage_modulair_pakket_thuis]" :ticksuffix="'%'" :showlegend="false" />
          </div>
        </card>
      </div>
    </div>

    <div class="row" v-if="selectedLevel === 'dementienetwerk'">
      <div class="col-sm-12 down">
        <card-small :cardId="'Leveringsvorm Wlz modulair pakket thuis'" v-if="selectedLevel === 'dementienetwerk'"
          :hovertext="'Deze informatie is vanwege het risico op onthulling van individuele zorginstellingen (voorlopig) alleen op zorgkantoorregio-niveau beschikbaar. De data over de mensen met dementie komt uit de cbs microdata.'">
          <template slot="card-header">Leveringsvorm Wlz modulair pakket thuis - uitsluitend op
            zorgkantoorregio-niveau</template>
        </card-small>
      </div>
    </div>

    <div class="row" v-if="selectedLevel === 'zorgkantoor'">
      <div class="col-sm-12 down">
        <card :cardId="`Leveringsvorm Wlz PGB in ${selectedYear}`"
          :hovertext="'Deze informatie is vanwege het risico op onthulling van individuele zorginstellingen (voorlopig) alleen op zorgkantoorregio-niveau beschikbaar. De data over de mensen met dementie komt uit de cbs microdata.'"
          :x="zorgkantoorregionaam_pgb" :y="percentage_pgb_table.map(value => `${value}%`)" :xtitle="'Regio namen'"
          :ytitle="'Percentage'" :tableHeight="selectedLevel === 'dementienetwerk' ? 1600 : 900">
          <template slot="card-header">Leveringsvorm Wlz PGB in {{ selectedYear }}</template>
          <div slot="card-body">
            In dit overzicht is het percentage mensen met dementie te zien dat Wlz ontvangt in de vorm van een PGB ten
            opzichte van het totaal aantal mensen dat Wlz ontvangt. Deze informatie is uitsluitend op
            zorgkantoorregio-niveau beschikbaar.
            <stacked-bar-chart-colors v-if="load.graphOne" :names="['PGB']" :ytitle="'% met PGB'"
              :x="[zorgkantoorregionaam_pgb]" :y="[percentage_pgb]" :ticksuffix="'%'" :showlegend="false" />
          </div>
        </card>
      </div>
    </div>

    <div class="row" v-if="selectedLevel === 'dementienetwerk'">
      <div class="col-sm-12 down">
        <card-small :cardId="'Leveringsvorm Wlz PGB'" v-if="selectedLevel === 'dementienetwerk'"
          :hovertext="'Deze informatie is vanwege het risico op onthulling van individuele zorginstellingen (voorlopig) alleen op zorgkantoorregio-niveau beschikbaar. De data over de mensen met dementie komt uit de cbs microdata.'">
          <template slot="card-header">Leveringsvorm Wlz PGB - uitsluitend op zorgkantoorregio-niveau</template>
        </card-small>
      </div>
    </div>

    <div class="row" v-if="selectedLevel !== 'landelijk'">
      <div class="col-sm-12 down">
        <card :cardId="`Leveringsvorm Wlz-zorg verschillende bevolkingsgroepen in ${selectedYear}`"
          :hovertext="'De onderstaande grafiek geeft percentages weer voor specifieke leveringsvormen ten opzichte van het aantal mensen (met dementie of in een bepaalde leeftijdsklasse van de algemene bevolking) dat Wlz ontvangt. De data over de mensen met dementie komt uit de cbs microdata. De data over de algemene bevolking komt uit: https://mlzopendata.cbs.nl/#/MLZ/nl/dataset/40055NED/table?dl=1F1DA&ts=1645170709155'"
          :x="bevolkingsgroep" :y="verblijf_table.map(value => `${value}%`)"
          :z="volledig_pakket_thuis_table.map(value => `${value}%`)"
          :w="modulair_pakket_thuis_table.map(value => `${value}%`)" :v="pgb_table.map(value => `${value}%`)"
          :xtitle="'Bevolkingsgroep'" :ytitle="'Verblijf'" :ztitle="'Volledig pakket thuis'"
          :wtitle="'Modulair pakket thuis'" :vtitle="'PGB Wlz'" :tableHeight='300'>
          <template slot="card-header">Leveringsvorm Wlz-zorg verschillende bevolkingsgroepen in
            {{ selectedYear }}</template>
          <div slot="card-body" v-if="selectedYear !== 2021">
            In onderstaand diagram worden de leveringsvormen weergegeven die gebruikt worden door verschillende
            bevolkingsgroepen. Hierin wordt een vergelijking gemaakt tussen de mensen met dementie en twee verschillende
            leeftijdsgroepen van de totale bevolking. De verdeling van de leveringsvormen is over de bevolkingsgroepen
            gelijk.
            <grouped-bar-chart :ytitle="'% met leveringsvorm'" :tickformat="',.0%'" v-if="load.graphTwo"
              :tickvals="[0, 1, 2, 3]" :tickangle="0"
              :names="['Verblijf', 'Volledig pakket thuis', 'Modulair pakket thuis', 'PGB Wlz']"
              :x="[bevolkingsgroep, bevolkingsgroep, bevolkingsgroep, bevolkingsgroep]"
              :y="[verblijf, volledig_pakket_thuis, modulair_pakket_thuis, pgb]" />
          </div>
          <div slot="card-body" v-if="selectedYear === 2021">
            Deze grafiek is nog niet beschikbaar voor <em><b class="blue">2021</b></em>.
          </div>
        </card>
      </div>
    </div>

    <!-- Landelijk Plots -->
    <div class="row" v-if="selectedLevel === 'landelijk'">
      <div class="col-sm-12 down">
        <card :cardId="'Leveringsvorm Wlz Verblijf'"
          :hovertext="'Bij het bepalen of iemand is opgenomen in een Wlz-instelling is gebruik gemaakt van de gegevens van het CAK (GEBWLZTAB), waarbij de opnamedatum is gedefinieerd als de datum waarop de levering van zorg in natura in een instelling is gestart (en gebruik van andere leveringsvormen dus wordt genegeerd). Deze informatie is vanwege het risico op onthulling van individuele zorginstellingen (voorlopig) alleen op zorgkantoorregio-niveau beschikbaar. De data over de mensen met dementie komt uit de cbs microdata.'"
          :x="jaar_leveringsvorm" :y="percentage_natura_table.map(value => `${value}%`)" :xtitle="'Jaar'"
          :ytitle="'Percentage'" :tableHeight="300">
          <template slot="card-header">Leveringsvorm Wlz Verblijf</template>
          <div slot="card-body">
            In dit overzicht staat het landelijk niveau van het percentage mensen met dementie dat Wlz-zorg met verblijf
            ontvangt, vergeleken met het totaal aantal mensen dat Wlz ontvangt.
            <stacked-bar-chart-colors v-if="load.graphOne" :isLandelijk="false" :ytitle="'% Wlz met verblijf'"
              :xtitle="'Jaar'" :x="[jaar_leveringsvorm]" :y="[percentage_natura]" :showlegend="false" :ticksuffix="'%'"
              :tickangle="0" />
          </div>
        </card>
      </div>
    </div>

    <div class="row" v-if="selectedLevel === 'landelijk'">
      <div class="col-sm-12 down">
        <card :cardId="'Leveringsvorm Wlz volledig pakket thuis'"
          :hovertext="'Deze informatie is vanwege het risico op onthulling van individuele zorginstellingen (voorlopig) alleen op zorgkantoorregio-niveau beschikbaar. De data over de mensen met dementie komt uit de cbs microdata.'"
          :x="jaar_leveringsvorm" :y="percentage_volledig_pakket_thuis_table.map(value => `${value}%`)" :xtitle="'Jaar'"
          :ytitle="'Percentage'" :tableHeight="300">
          <template slot="card-header">Leveringsvorm Wlz volledig pakket thuis</template>
          <div slot="card-body">
            In dit overzicht wordt de landelijke hoogte weergegeven van het percentage mensen met dementie dat de Wlz in
            de
            vorm van een compleet pakket thuis krijgt, vergeleken met het totaal aantal mensen dat de Wlz ontvangt.
            <stacked-bar-chart-colors v-if="load.graphOne" :ytitle="'% met volledig pakket thuis'" :isLandelijk="false"
              :xtitle="'Jaar'" :x="[jaar_leveringsvorm]" :y="[percentage_volledig_pakket_thuis]" :showlegend="false"
              :ticksuffix="'%'" :tickangle="0" />
          </div>
        </card>
      </div>
    </div>

    <div class="row" v-if="selectedLevel === 'landelijk'">
      <div class="col-sm-12 down">
        <card :cardId="'Leveringsvorm Wlz modulair pakket thuis'"
          :hovertext="'Deze informatie is vanwege het risico op onthulling van individuele zorginstellingen (voorlopig) alleen op zorgkantoorregio-niveau beschikbaar. De data over de mensen met dementie komt uit de cbs microdata.'"
          :x="jaar_leveringsvorm" :y="percentage_modulair_pakket_thuis_table.map(value => `${value}%`)" :xtitle="'Jaar'"
          :ytitle="'Percentage'" :tableHeight="300">
          <template slot="card-header">Leveringsvorm Wlz modulair pakket thuis</template>
          <div slot="card-body">
            In dit overzicht wordt de landelijke hoogte weergegeven van het percentage mensen met dementie dat de Wlz in
            de
            vorm van een modulair pakket thuis krijgt, vergeleken met het totaal aantal mensen dat de Wlz ontvangt.
            <stacked-bar-chart-colors v-if="load.graphOne" :ytitle="'% met modulair pakket thuis'" :isLandelijk="false"
              :xtitle="'Jaar'" :x="[jaar_leveringsvorm]" :y="[percentage_modulair_pakket_thuis]" :showlegend="false"
              :ticksuffix="'%'" :tickangle="0" />
          </div>
        </card>
      </div>
    </div>

    <div class="row" v-if="selectedLevel === 'landelijk'">
      <div class="col-sm-12 down">
        <card :cardId="'Leveringsvorm Wlz PGB'"
          :hovertext="'Deze informatie is vanwege het risico op onthulling van individuele zorginstellingen (voorlopig) alleen op zorgkantoorregio-niveau beschikbaar. De data over de mensen met dementie komt uit de cbs microdata.'"
          :x="jaar_leveringsvorm" :y="percentage_pgb_table.map(value => `${value}%`)" :xtitle="'Jaar'"
          :ytitle="'Percentage'" :tableHeight="300">
          <template slot="card-header">Leveringsvorm Wlz PGB</template>
          <div slot="card-body">
            In dit overzicht wordt landelijk de hoogte weergegeven van het percentage mensen met dementie dat de Wlz in
            de
            vorm van een PGB ontvangt, vergeleken met het totaal aantal mensen dat de Wlz ontvangt.
            <stacked-bar-chart-colors v-if="load.graphOne" :isLandelijk="false" :ytitle="'% met PGB'" :xtitle="'Jaar'"
              :x="[jaar_leveringsvorm]" :y="[percentage_pgb]" :showlegend="false" :ticksuffix="'%'" :tickangle="0" />
          </div>
        </card>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 down">
        <card :cardId="'Leveringsvorm Wlz-zorg over tijd'"
          :hovertext="'De onderstaande grafiek geeft percentages weer voor specifieke leveringsvormen ten opzichte van het aantal mensen met dementie dat Wlz ontvangt. De data over de mensen met dementie komt uit de cbs microdata.'"
          :x="jaar" :y="verblijf_j_table.map(value => `${value}%`)"
          :z="volledig_pakket_thuis_j_table.map(value => `${value}%`)"
          :w="modulair_pakket_thuis_j_table.map(value => `${value}%`)" :v="pgb_j_table.map(value => `${value}%`)"
          :xtitle="'Bevolkingsgroep'" :ytitle="'Verblijf'" :ztitle="'Volledig pakket thuis'"
          :wtitle="'Modulair pakket thuis'" :vtitle="'PGB Wlz'" :tableHeight='300'>
          <template slot="card-header">Leveringsvorm Wlz-zorg over tijd – nationale cijfers</template>
          <div slot="card-body">
            In deze grafiek worden de verschillende leveringsvormen op nationaal niveau over tijd weergegeven als
            percentage
            van het totaal aantal mensen met dementie dat enige vorm van Wlz ontvangt. Er is een trend te zien voor de
            leveringsvorm Wlz met verblijf. Over de jaren heen wordt hier minder gebruik van gemaakt. Daarnaast is er
            over
            de jaren heen juist een stijging te zien in het gebruik van een modulair pakket thuis of de leveringsvorm
            PGB.
            <grouped-bar-chart :ytitle="'% met leveringsvorm'" :tickformat="',.0%'" v-if="load.graphThree"
              :tickvals="[0, 1, 2, 3]" :tickangle="0"
              :names="['Verblijf', 'Volledig pakket thuis', 'Modulair pakket thuis', 'PGB Wlz']" :x="[jaar, jaar, jaar, jaar]"
              :y="[verblijf_j, volledig_pakket_thuis_j, modulair_pakket_thuis_j, pgb_j]"
              :ticktext="['2017', '2018', '2019', '2020']" />
          </div>
        </card>
      </div>
    </div>

    <Footer :adclogo="adclogo" :erasmuslogo="erasmuslogo" />
  </div>
</template>

<script>


import Card from '../components/Card'
import CardSmall from '../components/CardSmall'
import StackedBarChartColors from '../components/StackedBarChartColors'
import GroupedBarChart from '../components/GroupedBarChart'
import { mapGetters } from 'vuex'
import Footer from '../components/Footer'
import adclogo from '@/assets/ADC full logo.png'
import erasmuslogo from '@/assets/erasmuslogo.png'
import VueMeta from 'vue-meta';

export default {
  mixins: [VueMeta],
  metaInfo: {
    title: 'Leven met Dementie - Wlz',
  },
  components: { Card, CardSmall, StackedBarChartColors, GroupedBarChart, Footer },
  data() {
    return {
      load: {
        graphOne: false,
        graphTwo: false,
        graphThree: false,
      },
      adclogo: adclogo,
      erasmuslogo: erasmuslogo,
      jaar_leveringsvorm: [],
      zorgkantoorregionaam_natura: [],
      zorgkantoorregionaam_mpt: [],
      zorgkantoorregionaam_pgb: [],
      zorgkantoorregionaam_vpt: [],
      percentage_natura: [],
      percentage_natura_table: [],
      percentage_pgb: [],
      percentage_volledig_pakket_thuis: [],
      percentage_volledig_pakket_thuis_table: [],
      percentage_modulair_pakket_thuis: [],
      verblijf: [],
      pgb: [],
      volledig_pakket_thuis: [],
      modulair_pakket_thuis: [],
      verblijf_table: [],
      pgb_table: [],
      volledig_pakket_thuis_table: [],
      modulair_pakket_thuis_table: [],
      bevolkingsgroep: [],
      verblijf_j: [],
      pgb_j: [],
      volledig_pakket_thuis_j: [],
      modulair_pakket_thuis_j: [],
      percentage_pgb_table: [],
      percentage_modulair_pakket_thuis_table: [],
      jaar: [],
      verblijf_j_table: [],
      pgb_j_table: [],
      volledig_pakket_thuis_j_table: [],
      modulair_pakket_thuis_j_table: [],
    }
  },
  computed: {
    ...mapGetters(['selectedRegion', 'selectedLevel', 'selectedYear'])
  },
  watch: {
    '$store.state.selectedLevel': function (level) {
      this.loadAllData(level)
    },
    '$store.state.selectedYear': function () {
      this.loadAllData(this.$store.state.selectedLevel)
      this.loadBevolkingLeveringsvorm()
    }
  },
  methods: {
    loadAllData(level) {
      if (level === 'zorgkantoor') {
        this.$http.get(`/api/zorgkantoor_leveringsvorm/get_data/?jaar=${this.$store.state.selectedYear}`).then(
          (response) => {
            response.data.sort((a, b) => b.percentage_natura - a.percentage_natura)
            response.data = response.data.filter(x => x.zorgkantoorregionaam !== 'Missing')
            this.percentage_natura = response.data.map(d => (d.percentage_natura * 100))
            this.percentage_natura_table = response.data.map(d => (d.percentage_natura * 100).toFixed(1))
            this.zorgkantoorregionaam_natura = response.data.map(d => d.zorgkantoorregionaam)
            response.data.sort((a, b) => b.percentage_modulair_pakket_thuis - a.percentage_modulair_pakket_thuis)
            this.percentage_modulair_pakket_thuis = response.data.map(d => (d.percentage_modulair_pakket_thuis * 100))
            this.percentage_modulair_pakket_thuis_table = response.data.map(d => (d.percentage_modulair_pakket_thuis * 100).toFixed(1))
            this.zorgkantoorregionaam_mpt = response.data.map(d => d.zorgkantoorregionaam)
            response.data.sort((a, b) => b.percentage_pgb - a.percentage_pgb)
            this.percentage_pgb = response.data.map(d => (d.percentage_pgb * 100))
            this.percentage_pgb_table = response.data.map(d => (d.percentage_pgb * 100).toFixed(1))
            this.zorgkantoorregionaam_pgb = response.data.map(d => d.zorgkantoorregionaam)
            response.data.sort((a, b) => b.percentage_volledig_pakket_thuis - a.percentage_volledig_pakket_thuis)
            this.percentage_volledig_pakket_thuis = response.data.map(d => (d.percentage_volledig_pakket_thuis * 100))
            this.percentage_volledig_pakket_thuis_table = response.data.map(d => (d.percentage_volledig_pakket_thuis * 100).toFixed(1))
            this.zorgkantoorregionaam_vpt = response.data.map(d => d.zorgkantoorregionaam)
            this.load.graphOne = true
          }
        )
      }
      else if (level === 'landelijk') {
        this.$http.get(`/api/zorgkantoor_leveringsvorm/get_data/?landelijk=True`).then(
          (response) => {
            response.data.sort((a, b) => a.jaar - b.jaar)
            response.data = response.data.filter(x => x.zorgkantoorregionaam !== 'Missing')
            this.jaar_leveringsvorm = response.data.map(d => d.jaar)
            this.percentage_natura = response.data.map(d => (d.percentage_natura * 100))
            this.percentage_natura_table = response.data.map(d => (d.percentage_natura * 100).toFixed(1))
            this.zorgkantoorregionaam_natura = response.data.map(d => d.zorgkantoorregionaam)
            response.data.sort((a, b) => b.percentage_modulair_pakket_thuis - a.percentage_modulair_pakket_thuis)
            this.percentage_modulair_pakket_thuis = response.data.map(d => (d.percentage_modulair_pakket_thuis * 100))
            this.percentage_modulair_pakket_thuis_table = response.data.map(d => (d.percentage_modulair_pakket_thuis * 100).toFixed(1))
            this.zorgkantoorregionaam_mpt = response.data.map(d => d.zorgkantoorregionaam)
            response.data.sort((a, b) => b.percentage_pgb - a.percentage_pgb)
            this.percentage_pgb = response.data.map(d => (d.percentage_pgb * 100))
            this.percentage_pgb_table = response.data.map(d => (d.percentage_pgb * 100).toFixed(1))
            this.zorgkantoorregionaam_pgb = response.data.map(d => d.zorgkantoorregionaam)
            response.data.sort((a, b) => b.percentage_volledig_pakket_thuis - a.percentage_volledig_pakket_thuis)
            this.percentage_volledig_pakket_thuis = response.data.map(d => (d.percentage_volledig_pakket_thuis * 100))
            this.percentage_volledig_pakket_thuis_table = response.data.map(d => (d.percentage_volledig_pakket_thuis * 100).toFixed(1))
            this.zorgkantoorregionaam_vpt = response.data.map(d => d.zorgkantoorregionaam)
            this.load.graphOne = true
          }
        )
      }
    },
    loadBevolkingLeveringsvorm() {
      this.$http.get(`/api/bevolking_leveringsvorm/get_data/?jaar=${this.$store.state.selectedYear}`).then(
        (response) => {
          this.bevolkingsgroep = response.data.map(d => d.groep)
          this.pgb = response.data.map(d => (d.percentage_pgb))
          this.verblijf = response.data.map(d => (d.percentage_verblijf))
          this.modulair_pakket_thuis = response.data.map(d => (d.percentage_modulair_pakket_thuis))
          this.volledig_pakket_thuis = response.data.map(d => (d.percentage_volledig_pakket_thuis))

          this.pgb_table = response.data.map(d => (d.percentage_pgb * 100).toFixed(1))
          this.verblijf_table = response.data.map(d => (d.percentage_verblijf * 100).toFixed(1))
          this.modulair_pakket_thuis_table = response.data.map(d => (d.percentage_modulair_pakket_thuis * 100).toFixed(1))
          this.volledig_pakket_thuis_table = response.data.map(d => (d.percentage_volledig_pakket_thuis * 100).toFixed(1))
          this.load.graphTwo = true
        }
      )
      this.$http.get(`/api/bevolking_leveringsvorm/get_data`).then(
        (response) => {
          response.data = response.data.filter(x => x.groep === 'Mensen met dementie')
          this.jaar = response.data.map(d => d.jaar)
          this.pgb_j = response.data.map(d => (d.percentage_pgb))
          this.verblijf_j = response.data.map(d => (d.percentage_verblijf))
          this.modulair_pakket_thuis_j = response.data.map(d => (d.percentage_modulair_pakket_thuis))
          this.volledig_pakket_thuis_j = response.data.map(d => (d.percentage_volledig_pakket_thuis))

          this.pgb_j_table = response.data.map(d => (d.percentage_pgb * 100).toFixed(1))
          this.verblijf_j_table = response.data.map(d => (d.percentage_verblijf * 100).toFixed(1))
          this.modulair_pakket_thuis_j_table = response.data.map(d => (d.percentage_modulair_pakket_thuis * 100).toFixed(1))
          this.volledig_pakket_thuis_j_table = response.data.map(d => (d.percentage_volledig_pakket_thuis * 100).toFixed(1))
          this.load.graphThree = true
        }
      )
    }
  },
  mounted() {
    this.loadAllData(this.selectedLevel)
    this.loadBevolkingLeveringsvorm()
  }
}

</script>

<style scoped>
.intro-section-title {
  font-weight: bold;
  margin-bottom: 2rem;
}
</style>